.messages-container {
    padding: 2rem;
    text-align: center;
  }
  
  .messages-list {
    margin-top: 1rem;
    max-height: 200px;
    overflow-y: auto;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .message-item {
    margin: 0.5rem 0;
    padding: 0.5rem;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .message-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  
  .message-form input {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 0.5rem;
    width: 300px;
  }
  
  .message-form button {
    padding: 0.15rem 1rem;
    font-size: 0.75rem;
    border: none;
    border-radius: 5px;
    background-color: #4CAF50;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .message-form button:hover {
    background-color: #45a049;
  }
  
.photo-gallery {
  padding: 2rem;
  text-align: center;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.gallery-photo {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: 8px;
  transition: transform 0.2s;
}

.gallery-photo:hover {
  transform: scale(1.05);
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.lightbox img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
}

.lightbox-nav {
  position: absolute;
  top: 50%;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  color: #333;
  font-size: 2rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  cursor: pointer;
  z-index: 1001;
  transform: translateY(-50%);
}

.lightbox-nav.prev {
  left: 2%;
}

.lightbox-nav.next {
  right: 2%;
}

.lightbox-nav:hover {
  background: rgba(255, 255, 255, 0.9);
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

.pagination button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  font-size: 1rem;
  color: #333;
}

.countdown {
  text-align: center;
  padding: 3rem;
}

.timer {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
}

.time-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.time-box:hover {
  transform: scale(1.05);
}

.time {
  font-size: 2.5rem;
  font-weight: bold;
  color: #0071e3;
}

.label {
  font-size: 1rem;
  color: #666;
  margin-top: 5px;
}
